import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import type { SVGAttributes } from 'react';
import React, { forwardRef, useMemo } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { UnstyledButton } from '@kuna-pay/ui/ui/button';

import { useScrollToSelectedElement } from '../../components/select/lib';
import { DropdownIcon } from '../../icons';
import { Checkbox } from '../checkbox';
import { Skeleton } from '../skeleton';
import { Typography } from '../typography';
import { useSyncTriggerAndContentWidth } from './dropdown.lib';
import type {
  DropdownContentProps,
  DropdownItemTextProps,
  DropdownProps,
  DropdownSkeletonProps,
  DropdownTextProps,
  DropdownTriggerProps,
  DropdownTriggerTextProps,
} from './dropdown.props';
import styles from './dropdown.module.scss';

const DropdownText = forwardRef<HTMLSpanElement, DropdownTextProps>(
  (props, ref) => <Typography ref={ref} variant='numbers1' {...props} />
);

const DropdownItemText = forwardRef<HTMLSpanElement, DropdownItemTextProps>(
  ({ className, active, ...props }, forwardedRef) => {
    const ref = useScrollToSelectedElement(
      forwardedRef,
      (element) =>
        element.dataset.active === 'true' ||
        // explicitly check for empty string as `<DropdownItemText active />` can be rendered as `<span data-active />
        element.dataset.active === ''
    );

    return (
      <DropdownText
        ref={ref}
        className={clsx(styles.itemText, className)}
        data-active={!!active}
        {...props}
      />
    );
  }
);

const ROOT = document.getElementById('root');

const Dropdown = createCompoundComponent(
  ({ Root, Trigger, TriggerText, TriggerIndicator, Content }) =>
    ({
      //content
      children,
      before,
      content,

      //control
      open,
      onClose,
      onOpenChange,

      //behavior
      modal = false,
      asChild,
      disablePortal,

      //style
      maxDropdownHeight = 200,
      align,
      disableShadow,
      rawTrigger,
      dropShadow,
      width,
      palette,

      className,
      classes,
      style = {},
      collisionPadding,

      ...props
    }: DropdownProps) => {
      const syncWidth = useSyncTriggerAndContentWidth([children]);

      return (
        <Root open={open} modal={modal} onOpenChange={onOpenChange}>
          <Trigger
            ref={syncWidth.triggerRef}
            asChild={asChild}
            styled={!rawTrigger}
            style={{ width, ...style }}
            className={className}
            notAsChild={!asChild}
            palette={palette}
          >
            {asChild ? (
              children
            ) : (
              <>
                {before}

                <TriggerText
                  className={classes?.triggerText}
                  before={!!before}
                  {...props}
                >
                  {children}
                </TriggerText>

                <TriggerIndicator />
              </>
            )}
          </Trigger>

          {disablePortal ? (
            <Content
              align={align}
              style={{
                width,
                minWidth: width ?? syncWidth.width,
                maxHeight: maxDropdownHeight,
              }}
              shadow={!disableShadow}
              onInteractOutside={onClose}
              dropShadow={dropShadow}
              collisionPadding={collisionPadding}
            >
              {content}
            </Content>
          ) : (
            <RadixDropdown.Portal
              //@ts-expect-error TODO(Router)?????
              className={styles.content}
              //need container to fix overflow issue (modal should be over popover)
              container={ROOT}
            >
              <Content
                align={align}
                style={{
                  width,
                  minWidth: width ?? syncWidth.width,
                  maxHeight: maxDropdownHeight,
                }}
                shadow={!disableShadow}
                onInteractOutside={onClose}
                dropShadow={dropShadow}
                collisionPadding={collisionPadding}
              >
                {content}
              </Content>
            </RadixDropdown.Portal>
          )}
        </Root>
      );
    },
  {
    Root: RadixDropdown.Root,

    Trigger: forwardRef<HTMLButtonElement, DropdownTriggerProps>(
      ({ className, notAsChild, palette, styled, ...props }, ref) => (
        <RadixDropdown.Trigger
          ref={ref}
          className={clsx(
            UnstyledButton.className(),
            {
              [styles.trigger]: styled,
            },
            className
          )}
          data-not-as-child={!!notAsChild}
          data-palette={palette}
          {...props}
        />
      )
    ),

    TriggerText: forwardRef<HTMLSpanElement, DropdownTriggerTextProps>(
      ({ className, before, ...props }, ref) => (
        <DropdownText
          ref={ref}
          className={clsx(styles.triggerText, className)}
          data-before={!!before}
          {...props}
        />
      )
    ),

    TriggerIndicator: forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>(
      ({ className, ...props }, ref) => (
        <DropdownIcon
          ref={ref}
          className={clsx(styles.triggerIndicator, className)}
          {...props}
        />
      )
    ),

    Content: forwardRef<HTMLDivElement, DropdownContentProps>(
      (
        { className, shadow, dropShadow, collisionPadding = 20, ...props },
        ref
      ) => (
        <RadixDropdown.Content
          ref={ref}
          className={clsx(styles.content, className)}
          data-shadow={!!shadow}
          data-drop-shadow={!!dropShadow}
          sideOffset={4}
          collisionPadding={collisionPadding}
          {...props}
        />
      )
    ),

    Item: forwardRef<HTMLDivElement, RadixDropdown.DropdownMenuItemProps>(
      ({ className, ...props }, ref) => (
        <RadixDropdown.Item
          ref={ref}
          className={clsx(styles.item, className)}
          {...props}
        />
      )
    ),

    ItemText: DropdownItemText,

    Skeleton: ({ items = 5, ...props }: DropdownSkeletonProps) => (
      <>
        {useMemo(() => Array.from({ length: items }), []).map((_, index) => (
          <RadixDropdown.CheckboxItem
            key={index}
            textValue=''
            disabled
            {...props}
          >
            <Skeleton fullWidth />
          </RadixDropdown.CheckboxItem>
        ))}
      </>
    ),

    CheckboxItem: forwardRef<
      HTMLDivElement,
      RadixDropdown.DropdownMenuCheckboxItemProps
    >(({ checked, children, ...props }, ref) => (
      <RadixDropdown.CheckboxItem
        ref={ref}
        className={styles.item}
        checked
        {...props}
      >
        <DropdownItemText>{children}</DropdownItemText>

        <RadixDropdown.ItemIndicator className={styles.checkboxItemIndicator}>
          <Checkbox checked={checked} size='lg' />
        </RadixDropdown.ItemIndicator>
      </RadixDropdown.CheckboxItem>
    )),
  }
);

export { Dropdown };
export type { DropdownProps };
